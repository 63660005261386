import type { PortableTextBlock } from '@portabletext/types';

export interface AccountSettingsFormFields {
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  email: string;
  password: string;
  confirm: string;
  current: string;
  country: string;
  verificationCode: string;
}

export interface AddressFormFields {
  firstName: string;
  lastName: string;
  company: string;
  country: string;
  postcode: string;
  state: string;
  suburb: string;
  address1: string;
  address2: string;
  phone: string;
}

export interface CreateAccountFormFields {
  email: string;
  password: string;
  confirm: string;
  firstName: string;
  lastName: string;
  company: string;
  country: string;
  postcode: string;
  state: string;
  suburb: string;
  address1: string;
  address2: string;
  phone: string;
  newsletter: boolean;
  sms: boolean;
  requested_sample?: string;
  requested_sample_source?: string;
  barcode4State?: string;
  addressAutocomplete: string;
}

export interface NewsletterSubscriptionFields {
  firstName: string;
  email: string;
}

// #################################################

export const isRadioInput = (
  item: CustomFormInput | CustomFormInputWithOptions
): item is CustomFormInputWithOptions => item.type === inputTypes.radio;

export const isCheckboxInput = (
  item: CustomFormInput | CustomFormInputWithOptions
): item is CustomFormInputWithOptions => item.type === inputTypes.checkbox;

export const isSelectInput = (
  item: CustomFormInput | CustomFormInputWithOptions
): item is CustomFormInputWithOptions => item.type === inputTypes.select;

export const isTextareaInput = (
  item: CustomFormInput | CustomFormInputWithOptions
): item is CustomFormInputWithOptions => item.type === inputTypes.textarea;

export const isTelephoneInput = (
  item: CustomFormInput | CustomFormInputWithOptions
): item is CustomFormInput => item.type === inputTypes.tel;

export const isImageInput = (
  item: CustomFormInput | CustomFormInputWithOptions
): item is CustomFormInput => item.type === inputTypes.image;

export const isDateInput = (
  item: CustomFormInput | CustomFormInputWithOptions
): item is CustomFormInput => item.type === inputTypes.date;

export const isPortableText = (
  item: CustomFormInput | CustomFormInputWithOptions | FormTextBlock
): item is FormTextBlock => item._type === 'content';

export interface ValidationRules {
  required: boolean;
  minLength: number | null;
  maxLength: number | null;
  min: number | null;
  max: number | null;
}

export enum inputTypes {
  text = 'text',
  number = 'number',
  date = 'date',
  radio = 'radio',
  checkbox = 'checkbox',
  select = 'select',
  textarea = 'textarea',
  email = 'email',
  image = 'image',
  tel = 'tel',
}

export interface FormTextBlock {
  _key: string;
  _type: string;
  content: PortableTextBlock[];
}
export interface CustomFormInput {
  _key: string;
  _type: string;
  name: string;
  label: string;
  placeholder: string;
  type: inputTypes;
  validation: ValidationRules;
}

export interface CustomFormOptions {
  label: string;
  value?: string;
}

export interface CustomFormInputWithOptions extends CustomFormInput {
  options: Array<CustomFormOptions>;
  randomiseOptions: boolean;
}

export interface CustomFormFieldset {
  _key: string;
  title?: string;
  inputs: Array<CustomFormInput | CustomFormInputWithOptions | FormTextBlock>;
}

export interface CustomForm {
  _id: string;
  title: string;
  loggedInOnly: boolean;
  uniqueEntry: boolean;
  fieldsets: Array<CustomFormFieldset>;
  emails: Array<{ recipient: string; postmarkTemplateId: number }>;
}
